<template>
  <v-container fluid class="px-0">
    <posts-modal
      ref="detailsModal"
      :selectedData="selectedData"
      @GetList="GetList"
    />
    <MyDataTable
      title="گزارش تخلف های موجود"
      subtitle="مدیریت گزارش تخلف"
      :headers="headers"
      :items="items"
      :loading="loading"
      :pagesLength="pagesLength"
      @onPageChanged="GetList"
      :isRemoveEnabled="false"
      :isEditEnabled="false"
      :hasButton="false"
      class="mt-n13 moholand-shadow"
    >
      <template #contentTag="{ row }">
        <td>
          {{
            row.vendor && row.vendor.user
              ? row.vendor.user.first_name + " " + row.vendor.user.last_name
              : "-"
          }}
        </td>
        <td>
          {{
            row.user
              ? row.user.first_name + " " + row.user.last_name
              : row.name
              ? row.name
              : "-"
          }}
        </td>
        <td>
          {{
            row.user && row.user.email
              ? row.user.email
              : row.email
              ? row.email
              : "-"
          }}
        </td>
        <td>
          {{
            row.user && row.user.cellphone
              ? row.user.cellphone
              : row.cellphone
              ? row.cellphone
              : "-"
          }}
        </td>
        <td>{{ row.desc }}</td>
      </template>
    </MyDataTable>
  </v-container>
</template>

<script>
import { Constants } from "@/constants/GlobalConstants";
import MyAxios from "@/constants/MyAxios";
import MyDialog from "@/components/MyDialog";
import MyDataTable from "@/components/MyDataTable";
import PostsModal from "../../../../components/Panel/PostsModal.vue";

export default {
  created() {
    this.GetList();
  },
  components: {
    MyDialog,
    MyDataTable,
    PostsModal,
  },
  methods: {
    GetList() {
      if (!this.loading) this.loading = true;

      MyAxios.get("/reports/vendor/index", {
        params: {
          page: this.$route.query?.page || 1,
          with: ["user", "vendor", "vendor.user"],
        },
      })
        .then((response) => {
          this.loading = false;
          this.items = response.data.data;
          this.pagesLength = response.data.last_page;
        })
        .catch((error) => {
          this.loading = false;
        });
    },
    onEditClick(item) {
      this.$router.push({
        path: "/posts/edit/" + item.id,
        query: this.$route.query || {},
      });
    },
    onDetailsClick(item) {
      this.selectedData = item;
      this.$refs.detailsModal.dialog = true;
    },
    deleteItem(item) {
      MyAxios.delete(`/posts/${item.id}/delete`)
        .then((response) => {
          console.log("delete", response);
          this.$root.$emit("toast", {
            text: "آیتم با موفقیت حذف شد.",
            type: "success",
          });
          this.GetList();
        })
        .catch((error) => {
          console.log("laws", error.response);
        });
    },
    updateItems(items) {
      this.items = items;
    },
  },
  data() {
    return {
      loading: true,
      items: [],
      selectedData: {},
      page: 1,
      pagesLength: 1,
      path: "/reports/index",
      pathParams: {
        with: ["user", "vendor", "vendor.user"],
      },
      headers: [
        { text: "برای فروشنده", value: "user_id" },
        { text: "توسط", value: "user_id" },
        { text: "ایمیل", value: "category_id" },
        { text: "موبایل", value: "category_id" },
        { text: "توضیحات", value: "status" },
      ],
    };
  },
};
</script>

<style></style>
